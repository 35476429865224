import React, { useEffect, useRef } from 'react'
import { Box, Fade, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import SaaSPageQuery from '../queries/IndexPageQuery'
import Layout from '../components/Layout'
import Hero from '../components/HeroSaaS'
import Section from '../components/Section'
import Visible from '../components/Visible'
import OptImages from '../components/OptImages'
import EmoticonCaptionCard from '../components/EmoticonCaptionCard'
import ContactSection from '../components/ContactSection'
import styled from 'styled-components'

const StyledHowToColumn = styled(Box)`
  h4 {
    margin-top: -10px;
  }
  h1 {
    font-size: 6.8rem;
    margin-right: 5px;
    margin-top: 5px;
  }
`

export default () => {
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))

  const contactSectionRef = useRef(null)
  const scrollToRef = (ref) =>
    window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: 'smooth' })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        window.fbq('track', 'PageView')
      }
    }
  }, [])

  return (
    <SaaSPageQuery
      render={(content) => (
        <Layout>
          <Box marginTop={0} marginBottom={0}>
            <Hero
              ecenter1={<h2>Brandie SaaS enables you to harness the power of WoM on social media</h2>}
              link='Request Demo'
              onBtnClickHandler={(e) => scrollToRef(contactSectionRef)}
            />

            <Section
              centered
              py={5}
              px={smUp ? 8 : 2}
              gradient={[ '#66BF7E', '#5CB774' ]}
              flexDirection='column'
              position='relative'
              overflow='hidden'
              color='white'
              // svg={IconBrandieSvg}
              // svgProps={{ viewBox: '0 0 152.34 259.7' }}
              textAlign='center'
            >
              <Visible
                render={(isVisible, ref) => (
                  <Fade in={isVisible}>
                    <Box
                      maxWidth='950px'
                      display='block'
                      margin='0 auto'
                      marginBottom='45px'
                      textAlign='center'
                      color='white'
                    >
                      <h3 ref={ref}>A white-label community management platform</h3>
                    </Box>
                  </Fade>
                )}
              />
              <Grid container spacing={2} justify='center' alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <EmoticonCaptionCard
                    subTitle='Keep track of your Brand Ambassadors social media efforts on social media'
                    timeout={0}
                    image={<OptImages.EmojiStats />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <EmoticonCaptionCard
                    subTitle='Start educating your Ambassadors to become better on social media'
                    timeout={700}
                    image={<OptImages.EmojiRocket />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <EmoticonCaptionCard
                    subTitle='Incentivise your Ambassadors to start or share more content'
                    image={<OptImages.EmojiPhoneArrow />}
                    timeout={1400}
                  />
                </Grid>
              </Grid>
            </Section>

            <Section
              top
              py={5}
              px={smUp ? 8 : 2}
              gradient={[ '#6A6EC3', '#6A6EC3' ]}
            >
              <Visible
                render={(isVisible, ref) => (
                  <Fade in={isVisible}>
                    <Box
                      maxWidth='1280px'
                      display='block'
                      margin='0 auto'
                      marginTop='45px'
                      textAlign='center'
                      color='white'
                    >
                      <h4 ref={ref}>Increase the quality and frequency of UGC on social media, while making it brand aligned</h4>
                    </Box>
                  </Fade>
                )}
              />
              <Box height={80} />
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Box
                  margin='0 auto'
                  display='flex'
                  justifyContent='center'
                >
                  <OptImages.SaaSUGCChart
                    style={{ height: '100%', width: '70%', transform: 'scale(1.05)' }}
                  />
                </Box>
              </Grid>
            </Section>

            <Section
              centered
              py={5}
              px={smUp ? 8 : 2}
              flexDirection='row'
            >
              <Grid container>
                <StyledHowToColumn
                  display='flex'
                  flexDirection='column'
                >
                  <Box
                    display='flex'
                  >
                    <h1>1</h1>
                    <Box
                      display='flex'
                      flexDirection='column'
                    >
                      <p>Brand Ambassadors</p>
                      <h4>Customize Brand Content</h4>
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    marginTop='40px'
                    height='300px'
                  >
                    <OptImages.HowToAddStickers1
                      style={{ width: '50%', height: '110%', transform: 'scale(1.05)' }}
                    />
                    <OptImages.HowToAddStickers2
                      style={{ width: '50%', height: '110%', transform: 'scale(1.05)' }}
                    />
                  </Box>
                </StyledHowToColumn>
              </Grid>
              <Grid container>
                <StyledHowToColumn
                  display='flex'
                  flexDirection='column'
                  paddingLeft='40px'
                >
                  <Box
                    display='flex'
                  >
                    <h1>2</h1>
                    <Box
                      display='flex'
                      flexDirection='column'
                    >
                      <p>Brand Ambassadors</p>
                      <h4>Share to SoMe</h4>
                    </Box>
                  </Box>
                  <Box
                    height='300px'
                    marginTop='40px'
                  >
                    <Box
                      marginTop='-40px'
                    >
                      <OptImages.HowToAddCaption
                        style={{ height: '130%', transform: 'scale(0.87)' }}
                      />
                    </Box>
                  </Box>
                </StyledHowToColumn>
              </Grid>
              <Grid container>
                <StyledHowToColumn
                  display='flex'
                  flexDirection='column'
                >
                  <Box
                    display='flex'
                  >
                    <h1>3</h1>
                    <Box
                      display='flex'
                      flexDirection='column'
                    >
                      <p>You</p>
                      <h4>Track and measure effect</h4>
                    </Box>
                  </Box>
                  <Box
                    height='300px'
                    marginTop='40px'
                    paddingTop='40px'
                  >
                    <OptImages.DashboardScreenshot
                      style={{ width: '100%', transform: 'scale(1.15)' }}
                    />
                  </Box>
                </StyledHowToColumn>
              </Grid>
            </Section>

            <Section
              top
              py={5}
              px={smUp ? 8 : 2}
              gradient={[ '#54BAB3', '#54BAB3' ]}
            >
              <Visible
                render={(isVisible, ref) => (
                  <Fade in={isVisible}>
                    <Box
                      maxWidth='950px'
                      display='block'
                      margin='0 auto'
                      marginBottom='45px'
                      textAlign='center'
                      color='white'
                    >
                      <h3 ref={ref}>Fully own all features with your own community platform for both Android & iOS</h3>
                    </Box>
                  </Fade>
                )}
              />
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Box mb={0} mt={0}>
                  <OptImages.BrandieWhitelabelFlow
                    style={{ height: '100%', transform: 'scale(1.05)' }}
                  />
                </Box>
              </Grid>

            </Section>

            <Section
              centered
              py={5}
              px={smUp ? 8 : 2}
              gradient={[ '#C46DAD', '#C46DAD' ]}
              flexDirection='column'
              position='relative'
              overflow='hidden'
              color='white'
              // svg={IconBrandieSvg}
              // svgProps={{ viewBox: '0 0 152.34 259.7' }}
              textAlign='center'
            >
              <Visible
                render={(isVisible, ref) => (
                  <Fade in={isVisible}>
                    <Box
                      maxWidth='950px'
                      display='block'
                      margin='0 auto'
                      marginBottom='45px'
                      textAlign='center'
                      color='white'
                    >
                      <h3 ref={ref}>Features</h3>
                    </Box>
                  </Fade>
                )}
              />

              <Grid container spacing={2} justify='center' alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <EmoticonCaptionCard
                    subTitle='Track Social Media KPI'
                    timeout={0}
                    image={<OptImages.EmojiBarchart />}
                    radius={100}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <EmoticonCaptionCard
                    subTitle='Content Screening Tools'
                    timeout={700}
                    image={<OptImages.EmojiGreenCheck />}
                    radius={100}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <EmoticonCaptionCard
                    subTitle='Content Editor with custom brand assets'
                    image={<OptImages.EmojiCamera />}
                    timeout={1400}
                    radius={100}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <EmoticonCaptionCard
                    subTitle='In-app chat'
                    timeout={0}
                    image={<OptImages.EmojiSpeechBalloon />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <EmoticonCaptionCard
                    subTitle='Social media integrations'
                    timeout={700}
                    image={<OptImages.EmojiHashtag />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <EmoticonCaptionCard
                    subTitle='Mass briefs for social media activities'
                    image={<OptImages.EmojiRocket />}
                    timeout={1400}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <EmoticonCaptionCard
                    subTitle='Incentives with our scalable rewarding system'
                    timeout={0}
                    image={<OptImages.EmojiTrophy />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <EmoticonCaptionCard
                    subTitle='Get a custom dashboard to track effect'
                    timeout={700}
                    image={<OptImages.EmojiLaptop />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <EmoticonCaptionCard
                    subTitle='Campaign tools to ensure right use of hashtags & content'
                    image={<OptImages.EmojiStats />}
                    timeout={1400}
                  />
                </Grid>
              </Grid>

              <Box
                maxWidth='950px'
                display='block'
                margin='0 auto'
                marginBottom='45px'
                textAlign='center'
                color='white'
              >
                <h3>And much more...</h3>
              </Box>

            </Section>

            <ContactSection ref={contactSectionRef} />
          </Box>
        </Layout>
      )}
    />
  )
}
